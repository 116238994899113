import requests from '../utils'

async function sendCodes(data) {
  return (
    requests.auth.post(`${window.location.origin}/api/sendCodes`, data) || {}
  )
}

async function getLines(userIds = []) {
  return (
    requests.auth.get(
      `${window.location.origin}/api/getLines?${
        userIds.length > 0
          ? new URLSearchParams({
              userIds: userIds.join(',')
            })
          : ''
      }`
    ) || {}
  )
}

async function getLine(id) {
  return (
    requests.auth.get(
      `${window.location.origin}/api/getLine?${new URLSearchParams({
        id
      })}`
    ) || {}
  )
}

async function addLine(userId) {
  return (
    requests.auth.post(
      `${window.location.origin}/api/addLine?${new URLSearchParams({
        userId
      })}`
    ) || {}
  )
}

async function removeLine(id) {
  return (
    requests.auth.delete(
      `${window.location.origin}/api/removeLine?${new URLSearchParams({
        id
      })}`
    ) || {}
  )
}

async function editLineById(id, data) {
  return (
    (await requests.auth.patch(
      `${window.location.origin}/api/editLine?${new URLSearchParams({
        id
      })}`,
      data
    )) || {}
  )
}

async function getHistories(lineId) {
  return (
    requests.auth.get(
      `${window.location.origin}/api/getHistories?${new URLSearchParams({
        lineId
      })}`
    ) || {}
  )
}

export {
  sendCodes,
  getLines,
  getLine,
  addLine,
  removeLine,
  editLineById,
  getHistories
}
