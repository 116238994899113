import requests from '../utils'
import { writable } from 'svelte/store'

let user = {}
async function getMe() {
  if (Object.keys(user).length > 0) return user
  return (
    requests.auth
      .get(`${window.location.origin}/api/getMe`)
      .then((userData) => {
        user = userData
        return userData
      }) || {}
  )
}

async function getGISMTBalance() {
  return (
    requests.auth.get(`${window.location.origin}/api/gismt/getBalance`) || {}
  )
}

export { getMe, getGISMTBalance }
