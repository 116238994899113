import requests from '../utils'

async function getContractors() {
  return requests.auth.get(`${window.location.origin}/api/getContractors`) || []
}

async function getContractor(id) {
  return (
    requests.auth.get(
      `${window.location.origin}/api/getContractor?${new URLSearchParams({
        id
      })}`
    ) || []
  )
}

async function addContractor(userId) {
  return requests.auth.post(`${window.location.origin}/api/addContractor`) || {}
}

async function removeContractor(id) {
  return (
    requests.auth.delete(
      `${window.location.origin}/api/removeContractor?${new URLSearchParams({
        id
      })}`
    ) || {}
  )
}

async function editContractorById(id, data) {
  return (
    (await requests.auth.patch(
      `${window.location.origin}/api/editContractor?${new URLSearchParams({
        id
      })}`,
      data
    )) || {}
  )
}

export {
  getContractors,
  getContractor,
  addContractor,
  editContractorById,
  removeContractor
}
