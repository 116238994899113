import requests from '../utils'

async function getProducts() {
  return (
    (await requests.auth.get(
      `${window.location.origin}/api/gismt/getProducts?pg=${
        localStorage.getItem('pg') ?? ''
      }`
    )) || []
  )
}

async function getProductByGTIN(gtin) {
  return (
    (await requests.auth.get(
      `${window.location.origin}/api/gismt/getProduct/${gtin}`
    )) || {}
  )
}

async function editProductByGTIN(gtin, data) {
  return (
    (await requests.auth.patch(
      `${window.location.origin}/api/gismt/editProduct/${gtin}`,
      data
    )) || {}
  )
}

async function removeProductByGTIN(gtin) {
  return (
    (await requests.auth.delete(
      `${window.location.origin}/api/gismt/removeProduct/${gtin}`
    )) || {}
  )
}

async function refreshProducts() {
  return (
    (await requests.auth.put(
      `${
        window.location.origin
      }/api/gismt/refreshProducts/?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? ''
      })}`
    )) || []
  )
}

async function resetProducts() {
  return (
    (await requests.auth.delete(
      `${window.location.origin}/api/gismt/resetProducts/?${new URLSearchParams(
        { pg: localStorage.getItem('pg') ?? '' }
      )}`
    )) || []
  )
}

export {
  getProducts,
  getProductByGTIN,
  editProductByGTIN,
  removeProductByGTIN,
  refreshProducts,
  resetProducts
}
