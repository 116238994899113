<script>
  export let ref

  function onClickDialog(event) {
    if (event.target === ref && !event.target.classList.contains('modal'))
      ref.close()
  }

  function showModal() {
    ref.showModal()
  }
  function close() {
    ref.close()
  }
  export { showModal, close }
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<dialog bind:this={ref} on:mousedown={onClickDialog} class="absolute-center">
  <header>
    <slot name="title"></slot>
  </header>
  <div class="modal">
    <slot></slot>
  </div>
</dialog>

<style>
  dialog {
    overflow: hidden;
    border-radius: 1.2rem;
    background-color: var(--clr-gray-300);
    border: 0.1rem solid var(--clr-gray-400);
    font-size: 2.8rem;
    min-width: 47rem;
    width: max-content;
    max-width: 60rem;

    & header {
      padding: 2rem 2.5rem 1.75rem;
      color: var(--clr-black);
      font-weight: 600;
      border-bottom: 0.1rem solid var(--clr-gray-400);
    }

    & > .modal {
      padding: 1.8rem 2.5rem 1.5rem;

      & > p {
        font-weight: 600;
        color: var(--clr-gray-400);
        margin-bottom: 1rem;

        & > span {
          color: var(--clr-black);
        }
      }

      & form {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        margin-bottom: 1.5rem;
      }

      & #seria-wrapper {
        display: grid;
        grid-template-columns: auto calc(3ch + 4rem);
      }

      & > div:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>
