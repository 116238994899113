const HomePage = () => import('./lib/routes/HomePage.svelte')
const LoginPage = () => import('./lib/routes/LoginPage.svelte')
const RegisterPage = () => import('./lib/routes/RegisterPage.svelte')
const NotFoundPage = () => import('./lib/routes/NotFoundPage.svelte')
const OrdersPage = () => import('./lib/routes/OrdersPage.svelte')
const ProductsPage = () => import('./lib/routes/ProductsPage.svelte')
const ProductsInfoPage = () => import('./lib/routes/ProductsInfoPage.svelte')
const ReportsPage = () => import('./lib/routes/ReportsPage.svelte')
const ReportsNewApplicationPage = () =>
  import('./lib/routes/ReportsNewApplicationPage.svelte')
const ReportsNewCirculationPage = () =>
  import('./lib/routes/ReportsNewCirculationPage.svelte')
const StoragePage = () => import('./lib/routes/StoragePage.svelte')
const LinesPage = () => import('./lib/routes/LinesPage.svelte')
const LineInfoPage = () => import('./lib/routes/LineInfoPage.svelte')
const AdminPage = () => import('./lib/routes/AdminPage.svelte')
const OrdersNewPage = () => import('./lib/routes/OrdersNewPage.svelte')
const SettingsPage = () => import('./lib/routes/SettingsPage.svelte')
const ContractorsPage = () => import('./lib/routes/ContractorsPage.svelte')
const ContractorEditPage = () =>
  import('./lib/routes/ContractorEditPage.svelte')

const routes = [
  {
    name: `/`,
    component: HomePage
  },
  {
    name: `/contractors`,
    component: ContractorsPage
  },
  {
    name: `/contractors/:id`,
    component: ContractorEditPage
  },
  {
    name: '/products',
    component: ProductsPage
  },
  {
    name: '/admin',
    component: AdminPage
  },
  {
    name: '/products/:gtin',
    component: ProductsInfoPage
  },
  {
    name: '/orders',
    component: OrdersPage
  },
  {
    name: '/orders/new',
    component: OrdersNewPage
  },
  {
    name: '/reports/new/circulation',
    component: ReportsNewCirculationPage
  },
  {
    name: '/reports/new/apply',
    component: ReportsNewApplicationPage
  },
  {
    name: '/reports',
    component: ReportsPage
  },
  {
    name: '/storage',
    component: StoragePage
  },
  {
    name: '/lines',
    component: LinesPage
  },
  {
    name: '/lines/:id',
    component: LineInfoPage
  },
  {
    name: `/login`,
    component: LoginPage
  },
  {
    name: `/register`,
    component: RegisterPage
  },
  {
    name: `/settings`,
    component: SettingsPage
  },
  {
    name: `/*`,
    component: NotFoundPage
  }
]

export default routes
