import { expDateEnum } from './dicts'
import { navigateTo } from './router/Navigate'

async function handleResponse(response, requestHeaders) {
  let responseText
  try {
    responseText = await response.text()
    if (response.status === 200) {
      if (
        response.headers.get('content-type').indexOf('application/json') > -1 &&
        (requestHeaders.get('accept').indexOf('application/json') > -1 ||
          requestHeaders.get('accept').indexOf('*/*') > -1)
      )
        return JSON.parse(responseText)
      return responseText
    }
    response.text = () => responseText
    return Promise.reject(response)
  } catch (e) {
    console.error(responseText)
    response.text = () => responseText
    return Promise.reject(response)
  }
}

const getHeaders = {
  Accept: 'application/json'
}
const deleteHeaders = getHeaders

const postHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}
const patchHeaders = postHeaders
const putHeaders = postHeaders

async function getRequest(url, params = { headers: {} }) {
  const headers = new Headers(Object.assign({}, getHeaders, params.headers))
  const requestParams = {
    ...params,
    method: 'GET',
    headers
  }
  return handleResponse(await fetch(url, requestParams), headers)
}

async function deleteRequest(url, params = { headers: {} }) {
  const headers = new Headers(Object.assign({}, deleteHeaders, params.headers))
  const requestParams = {
    ...params,
    method: 'DELETE',
    headers
  }
  return handleResponse(await fetch(url, requestParams), headers)
}

async function postRequest(url, data, params = { headers: {} }) {
  const headers = new Headers(Object.assign({}, postHeaders, params.headers))
  const requestParams = {
    ...params,
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  }
  return handleResponse(await fetch(url, requestParams), headers)
}

async function patchRequest(url, data, params = { headers: {} }) {
  const headers = new Headers(Object.assign({}, patchHeaders, params.headers))
  const requestParams = {
    ...params,
    method: 'PATCH',
    headers,
    body: JSON.stringify(data)
  }
  return handleResponse(await fetch(url, requestParams), headers)
}

async function putRequest(url, data, params = { headers: {} }) {
  const headers = new Headers(Object.assign({}, putHeaders, params.headers))
  const requestParams = {
    ...params,
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  }
  return handleResponse(await fetch(url, requestParams), headers)
}

let authGet, authDelete, authPost, authPatch, authPut

{
  function getNovaToken() {
    const headers = {
      Accept: 'application/json'
    }

    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== null) headers['Device-ID'] = deviceId

    return getRequest(`${window.location.origin}/api/auth/token`, {
      headers,
      credentials: 'include'
    })
      .then((response) => {
        token = response.token
        return Promise.resolve(true)
      })
      .catch((e) => {
        console.error(e)
        navigateTo('/')
      })
  }

  function handleError(method, data = null) {
    const dict = {
      GET: authGet,
      DELETE: authDelete,
      POST: authPost,
      PATCH: authPatch,
      PUT: authPut
    }
    return async function (response) {
      if (response.status === 401) {
        const error = JSON.parse(response.text()).error
        if (error.message.endsWith('токен ГИС МТ')) {
          localStorage.removeItem('gismtToken')
          localStorage.removeItem('pg')
          if (window.location.pathname !== '/') {
            window.location.pathname = '/logout'
          }
        } else if (error.message.endsWith('токен OMS')) {
          localStorage.removeItem('omsToken')
          if (window.location.pathname !== '/') {
            navigateTo('/')
          }
        } else {
          return getNovaToken()
            .then((success) => {
              if (!success) return Promise.reject()

              if (data !== null)
                return setTimeout(() => dict[method](response.url, data), 5000)
              return setTimeout(() => dict[method](response.url), 5000)
            })
            .catch(() => {
              window.location.pathname = '/logout'
            })
        }
      } else {
        try {
          const body = JSON.parse(response.text())
          return body
        } catch (error) {
          return {
            errors: [{ message: response.statusText, code: response.status }]
          }
        }
      }
    }
  }

  let token

  const gismtTokenHrefs = ['/api/getMe', '/api/getReports']

  authGet = async function authGet(url, userHeaders = {}) {
    if (
      !token ||
      parseJWT(token).payload.exp < Math.floor(Date.now() / 1000) + 5
    )
      await getNovaToken()
    const headers = {
      ...userHeaders,
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }

    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== null) headers['Device-ID'] = deviceId

    if (
      url.indexOf('/api/gismt/') !== -1 ||
      gismtTokenHrefs.findIndex((href) => url.indexOf(href) !== 1) !== -1
    ) {
      const gismtToken = localStorage.getItem('gismtToken')
      if (gismtToken !== null) headers['GISMT-TOKEN'] = gismtToken
    }

    if (
      url.indexOf('/api/oms/') !== -1 ||
      url.indexOf('/api/getReports') !== -1
    ) {
      const omsToken = localStorage.getItem('omsToken')
      if (omsToken !== null) headers['OMS-TOKEN'] = omsToken
    }

    return getRequest(url, {
      headers
    }).catch(handleError('GET'))
  }

  authDelete = async function authDelete(url, data, userHeaders = {}) {
    if (
      !token ||
      parseJWT(token).payload.exp < Math.floor(Date.now() / 1000) + 5
    )
      await getNovaToken()
    const headers = {
      ...userHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== null) headers['Device-ID'] = deviceId

    if (
      url.indexOf('/api/gismt/') !== -1 ||
      gismtTokenHrefs.findIndex((href) => url.indexOf(href) !== 1) !== -1
    ) {
      const gismtToken = localStorage.getItem('gismtToken')
      if (gismtToken !== null) headers['GISMT-TOKEN'] = gismtToken
    }

    if (
      url.indexOf('/api/oms/') !== -1 ||
      url.indexOf('/api/getReports') !== 1
    ) {
      const omsToken = localStorage.getItem('omsToken')
      if (omsToken !== null) headers['OMS-TOKEN'] = omsToken
    }

    return await deleteRequest(url, {
      headers
    }).catch(handleError('DELETE'))
  }

  authPost = async function authPost(url, data, userHeaders = {}) {
    if (
      !token ||
      parseJWT(token).payload.exp < Math.floor(Date.now() / 1000) + 5
    )
      await getNovaToken()
    const headers = {
      ...userHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== null) headers['Device-ID'] = deviceId

    if (
      url.indexOf('/api/gismt/') !== -1 ||
      gismtTokenHrefs.findIndex((href) => url.indexOf(href) !== 1) !== -1
    ) {
      const gismtToken = localStorage.getItem('gismtToken')
      if (gismtToken !== null) headers['GISMT-TOKEN'] = gismtToken
    }

    if (
      url.indexOf('/api/oms/') !== -1 ||
      url.indexOf('/api/getReports') !== 1
    ) {
      const omsToken = localStorage.getItem('omsToken')
      if (omsToken !== null) headers['OMS-TOKEN'] = omsToken
    }

    return await postRequest(url, data, {
      headers
    }).catch(handleError('POST', data))
  }

  authPatch = async function authPatch(url, data, userHeaders = {}) {
    if (
      !token ||
      parseJWT(token).payload.exp < Math.floor(Date.now() / 1000) + 5
    )
      await getNovaToken()
    const headers = {
      ...userHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== null) headers['Device-ID'] = deviceId

    if (
      url.indexOf('/api/gismt/') !== -1 ||
      gismtTokenHrefs.findIndex((href) => url.indexOf(href) !== 1) !== -1
    ) {
      const gismtToken = localStorage.getItem('gismtToken')
      if (gismtToken !== null) headers['GISMT-TOKEN'] = gismtToken
    }

    if (
      url.indexOf('/api/oms/') !== -1 ||
      url.indexOf('/api/getReports') !== 1
    ) {
      const omsToken = localStorage.getItem('omsToken')
      if (omsToken !== null) headers['OMS-TOKEN'] = omsToken
    }

    return await patchRequest(url, data, {
      headers
    }).catch(handleError('PATCH', data))
  }

  authPut = async function authPut(url, data, userHeaders = {}) {
    if (
      !token ||
      parseJWT(token).payload.exp < Math.floor(Date.now() / 1000) + 5
    )
      await getNovaToken()
    const headers = {
      ...userHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== null) headers['Device-ID'] = deviceId

    if (
      url.indexOf('/api/gismt/') !== -1 ||
      gismtTokenHrefs.findIndex((href) => url.indexOf(href) !== 1) !== -1
    ) {
      const gismtToken = localStorage.getItem('gismtToken')
      if (gismtToken !== null) headers['GISMT-TOKEN'] = gismtToken
    }

    if (
      url.indexOf('/api/oms/') !== -1 ||
      url.indexOf('/api/getReports') !== 1
    ) {
      const omsToken = localStorage.getItem('omsToken')
      if (omsToken !== null) headers['OMS-TOKEN'] = omsToken
    }

    return await putRequest(url, data, {
      headers
    }).catch(handleError('PUT', data))
  }
}

function parseJWT(jwt) {
  const parts = jwt.split('.')
  const result = parts.map((part, i) =>
    i < 2 && part?.length > 0
      ? JSON.parse(atob(part.replace(/-/g, '+').replace(/_/g, '/')))
      : null
  )
  result.length = 2
  return { header: result[0], payload: result[1] }
}

function formatDate(date, format) {
  const dateObject = new Date(date)
  const tempFormat = format
  return tempFormat
    .replaceAll('{year}', dateObject.getFullYear())
    .replaceAll(
      '{month}',
      (dateObject.getMonth() + 1).toString().padStart(2, '0')
    )
    .replaceAll('{day}', dateObject.getDate().toString().padStart(2, '0'))
    .replaceAll('{minute}', dateObject.getMinutes().toString().padStart(2, '0'))
    .replaceAll('{hour}', dateObject.getHours().toString().padStart(2, '0'))
}

function createEnum(keys, values) {
  if (!Array.isArray(keys) || !Array.isArray(values)) return {}
  if (keys.length !== values.length) return {}

  let result = []
  for (let i = 0; i < keys.length; i++) {
    result[(result[values[i]] = keys[i])] = values[i]
  }
  return result
}

function setExpDateByEnum(expDate, fromDate = Date.now()) {
  if (expDate === 'неизвестно') return false

  const result = new Date(fromDate)
  const splittedExpDate = expDate.split(' ')
  const [count, value] = [
    Number(splittedExpDate.shift()),
    splittedExpDate.join(' ')
  ]

  function setHours(hours) {
    const secondInHour = 60 * 60 * 1000
    result.setTime(result.getTime() + hours * secondInHour)
  }

  function setDate(days) {
    result.setDate(result.getDate() + days)
  }

  function setMonth(months) {
    result.setMonth(result.getMonth() + months)
  }

  function setYear(years) {
    result.setFullYear(result.getFullYear() + years)
  }

  const setFuncArray = [setHours, setDate, setMonth, setYear]

  setFuncArray[expDateEnum[value]](count)
  return result
}

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)))
}

export default {
  get: getRequest,
  delete: deleteRequest,
  post: postRequest,
  patch: patchRequest,
  auth: {
    get: authGet,
    delete: authDelete,
    post: authPost,
    patch: authPatch,
    put: authPut
  }
}
export { parseJWT }
export { formatDate }
export { createEnum }
export { setExpDateByEnum }
export { utf8_to_b64, b64_to_utf8 }

function rangeDate({ currentTarget: el }) {
  const [min, value, max] = [el.min, el.value, el.max].map((date, i) => {
    if (date === '') {
      if (i === 0) {
        return { number: Number.NEGATIVE_INFINITY, value: null }
      }
      if (i === 2) {
        date = new Date().toISOString()
      }
      if (i === 2) {
        return { number: Number.POSITIVE_INFINITY, value: null }
      }
    }
    return { number: Date.parse(date), value: date }
  })
  el.value = [min, value, max].find(
    (val) =>
      val.number === Math.min(max.number, Math.max(value.number, min.number))
  ).value
}

export { rangeDate }

function inputDateFormat(date, withTime = false) {
  if (Number.isNaN(date)) return

  const dateConfig = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const dateTimeConfig = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }

  const dateInstance = new Date(date)

  return dateInstance
    .toLocaleString('sv-SE', withTime ? dateTimeConfig : dateConfig)
    .replace(' ', 'T')
}

export { inputDateFormat }

function fixInvalidDate(dateString) {
  const dateParts = dateString.split(/[-T:]/g)
  const [year, month, day] = dateParts.map(Number)
  const date = new Date()
  date.setFullYear(year)
  date.setMonth(month - 1)
  date.setDate(day)
  return inputDateFormat(date, dateParts.length > 3)
}

export { fixInvalidDate }

async function writeFilesToDirectory(files) {
  async function verifyPermission(fileHandle) {
    const options = {
      mode: 'readwrite'
    }
    return (
      (await fileHandle.queryPermission(options)) === 'granted' ||
      (await fileHandle.requestPermission(options)) === 'granted'
    )
  }

  const dirHandle = await window.showDirectoryPicker({ mode: 'readwrite' })
  for (const file of files) {
    const fileHandle = await dirHandle.getFileHandle(file.name, {
      create: true
    })
    if (await verifyPermission(fileHandle)) {
      const writable = await fileHandle.createWritable()
      await writable.write(file)
      await writable.close()
    }
  }
}

export { writeFilesToDirectory }

function chunk(array, size) {
  const chunked = [],
    tempArray = Array.from(array)

  while (tempArray.length > 0) {
    chunked.push(tempArray.splice(0, size))
  }
  return chunked
}

export { chunk }
