<script>
  import { onMount, createEventDispatcher, onDestroy } from 'svelte'

  const dispatch = createEventDispatcher()

  export let value
  export let list = []
  export let readonly
  export let disabled
  export let withIcons

  let input,
    datalist,
    isAfterMount = false

  let prevValue = value

  const abortController = new AbortController()

  onMount(init)

  onDestroy(() => abortController.abort())

  function init() {
    value = list.find((item) => item.value === value)?.value ?? list[0]?.value
    const currentItem = list.find((item) => {
      return value === item.value
    }) || { text: '', value: '' }
    input.value =
      currentItem.text ??
      currentItem.value ??
      list[0].text ??
      list[0].value ??
      ''
    document.addEventListener(
      'click',
      (e) => {
        if (
          input.parentNode !== e.target &&
          !input.parentNode.contains(e.target)
        )
          setTimeout(onBlur)
      },
      { signal: abortController.signal }
    )
    if (!isAfterMount) {
      dispatch('init', {})
    }
    isAfterMount = true
  }

  function onInput() {
    const text = input.value.toUpperCase()
    for (let option of datalist.options) {
      const startIndexValue = option.textContent.toUpperCase().indexOf(text)
      if (startIndexValue > -1) {
        option.style.display = 'block'
      } else {
        option.style.display = 'none'
      }
    }
  }

  function onBlur() {
    let isValid =
      list.findIndex(
        (item) => input.value === item.text || input.value === item.value
      ) > -1
    if (!isValid) {
      value = list[0].value
      input.value = list[0].text ?? list[0].value
    }
    input.blur()
    input.classList.remove('show')
    datalist.classList.remove('show')
    dispatch('input', {})
    if (prevValue !== value) dispatch('change', {})
    prevValue = value
  }

  function onFocus() {
    datalist.scrollTop = 0
    input.classList.add('show')
    datalist.classList.add('show')
  }

  $: {
    isAfterMount && list && init()
  }
</script>

<input
  class:icons={withIcons}
  type="text"
  list
  {readonly}
  disabled={disabled || list.length === 0}
  on:focus={onFocus}
  on:input={onInput}
  on:mousedown={(e) => {
    if (e.button === 0 && readonly && datalist.children.length > 0) {
      e.preventDefault()
      input.classList.toggle('show', datalist.classList.toggle('show'))
        ? onFocus()
        : onBlur()
    }
  }}
  bind:this={input}
  {...$$restProps}
/>
<datalist bind:this={datalist}>
  {#each list.filter((option) => !option?.disabled) as option (option.value)}
    <option
      class:icons={option.icon}
      style={option.icon && `background-image: url('${option.icon}')`}
      on:mousedown={() => {
        value = option.value
        input.value = option.text ?? option.value
        input.classList.remove('show')
        datalist.classList.remove('show')
        onBlur()
      }}
      value={option.value}>{option.text ?? option.value}</option
    >
  {/each}
</datalist>

<style>
  :global(.input):has(input) {
    position: relative;
  }
  input {
    z-index: 2;
    transition: z-index 0s 0.2s;
    &.icons {
      padding-left: 4.3rem;
    }

    &.show {
      z-index: 4;
      transition: z-index 0s 0s;
    }
  }

  datalist {
    background: var(--clr-white);
    z-index: 3;
    display: block;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 0rem;
    top: 3rem;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition:
      top 0.2s,
      max-height 0.2s,
      opacity 0.2s,
      visibility 0.2s;

    &:not(:empty) {
      padding: 0.5rem 0;
    }

    &.show {
      top: 9.5rem;
      max-height: 30rem;
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  option {
    user-select: none;
    cursor: pointer;
    background-color: var(--clr-white);
    height: 6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 2.8rem;
    padding: 1.2rem 2rem;
    box-shadow: inset 0 0 0 1px var(--clr-accent);

    &.icons {
      padding-left: 4.3rem;
      background-repeat: no-repeat;
      background-size: 2.4rem 2.4rem;
      background-position: 1.4rem center;
    }

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    &:hover {
      background-color: hsl(0, 0%, 97.5%);
    }
  }
</style>
